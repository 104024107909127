import React from "react";
import PropTypes from "prop-types";
import { NavLink, withRouter } from "react-router-dom";
import cx from "classnames";
import { connect } from "react-redux";

import { getPublicCategories } from "../../actions/action_categories";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";
import ListItemText from "@material-ui/core/ListItemText";
import PersonIcon from "@material-ui/icons/Person";
import Grid from "@material-ui/core/Grid";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Typography } from "@material-ui/core";

import mainNavBarStyle from "assets/jss/material-dashboard-pro-react/components/mainNavBarStyle.jsx";
import { bindActionCreators } from "redux";
import { getStore } from "../../store/configureStore";
import {updateIntlProv} from "../../utils/intl";
import { IntlProvider, addLocaleData } from "react-intl";
import en from "react-intl/locale-data/en";
import es from "react-intl/locale-data/es";
import { flattenMessages } from "../../utils/helper";
import messages from "../../locale/index";
addLocaleData([...en, ...es]);

class MainNavBar extends React.Component {
  ShowTheLocationWithRouter = withRouter(MainNavBar);
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      anchorEl: null,
      lang: "en",
    };
    this.props.getPublicCategories({});
  }
  
  componentDidUpdate(prevProps, prevState) {
    
  }

  servicesMenuOpen(event) {
    this.setState({ anchorEl: event.currentTarget });
  }

  servicesMenuClose(event, obj) {
    this.setState({ anchorEl: null });
    if (obj && obj.id) {
      let name = obj.name && obj.name.replace(/\s/g, "").toLowerCase();
      this.props.history.push("/auth/search-service/" + obj.id + "/" + name);
      window.location.reload(false);
      //  this.topNavRoutes(event, type);
    }
  }

  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? true : false;
  }

  topNavRoutes(event, property, filterObj = []) {
    switch (property) {
      case "home":
        this.props.history.push("/auth/home");
        break;
      case "about":
        this.props.history.push("/auth/about-page");
        break;
      case "treatment":
        this.props.history.push("/auth/search-service/3/treatmentservices");
        break;
      case "harm":
        this.props.history.push("/auth/search-service/4/harmreduction");
        break;
      case "peerandfamily":
        this.props.history.push("/auth/search-service/5/peerandfamilysupport");
        break;
      case "crisisHotline":
        this.props.history.push("/auth/hotlinecategories");
        break;
      case "freeNaloxone":
        this.props.history.push("/auth/search-service/4/harmreduction?filter=" + JSON.stringify(filterObj));
        break;
      case "faq":
        this.props.history.push("/auth/FAQs");
        break;
      case "events":
        this.props.history.push("/auth/events");
        break;
      default:
        break;
    }
  }
  changeLanguage(event){
    // Change language back to back from Espanol to English
    const store = getStore();
    const store_state = store.getState();
    if (store_state.langReducer.lang === "es"){
      store.dispatch({
        type : "CHANGE_LANG_ENGLISH"
      });  
      this.setState({lang : "en"})
      updateIntlProv("en")
    }else{
      store.dispatch({
        type : "CHANGE_LANG_SPANISH"
      });
      this.setState({lang : "es"})
      updateIntlProv("es")
    }
  }

  render() {
    const { classes, categories } = this.props;
    const { anchorEl } = this.state;

    return (
      <IntlProvider locale={this.state.lang} messages={flattenMessages(messages[this.state.lang])}>
      <div className={classes.navBar + " navbar-container"}>
        <Grid container>
          <Grid item md={2}>
            <Button
              className={classes.mainlogo}
              // style={{ padding: "6px 30px" }}
              // href="/auth/home"
              onClick={() => this.props.history.push('/auth/home')}
            >
              {" "}
              {/*  color="transparent" */}
              <img
                alt="DrugHelp Logo"
                src={require("../../assets/img/drughelp.png")}
                className={classes.imageProps}
              />
            </Button>
          </Grid>

          {window.location.href.includes("develop.drughelp.care") && (
            <Grid item xs={12} sm={3} className={classes.devWarning}>
              <Typography variant="subtitle1" color="error">
                Development Server
              </Typography>
            </Grid>
          )}

          <Grid item xs={12} md={3} className={classes.menuItems}>
            <Button
              onClick={(e) => {
                this.topNavRoutes(e, "home");
              }}
              className={classes.submenu}
            >
              {window.intl.formatMessage({ id: "home" })}
            </Button>
            <Button
              onClick={(e) => {
                this.topNavRoutes(e, "about");
              }}
              className={classes.submenu}
            >
              {window.intl.formatMessage({ id: "about" })}
            </Button>
            <Button
              aria-owns={anchorEl ? "services-menu" : null}
              aria-haspopup="true"
              onClick={(e) => {
                this.servicesMenuOpen(e);
              }}
              className={classes.submenu}
            >
              {window.intl.formatMessage({ id: "menu" })}
              <ExpandMoreIcon />
            </Button>
            <Menu
              id="services-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={(e) => {
                this.servicesMenuClose(e, null);
              }}
              MenuListProps={{
                name: "Services",
              }}
              style={{
                top:"5%"
              }}
            >
              {(categories.data || []).map((obj, i) => (
                <MenuItem
                  key={i}
                  onClick={(e) => {
                    this.servicesMenuClose(e, obj);
                  }}
                >
                  {" "}
                  {obj.name}{" "}
                </MenuItem>
              ))}
              <MenuItem
                onClick={(e) => {
                  this.topNavRoutes(e, "crisisHotline");
                  this.servicesMenuClose(e, null);
                }}
              >
                Crisis Hotlines
              </MenuItem>
              <MenuItem
                onClick={(e) => {
                  this.topNavRoutes(e, "freeNaloxone", [{ name: "Naloxbox Locations" }, { name: "Narcan (Naloxone)" }]);
                  this.servicesMenuClose(e, null);
                }}
              >
                Free Naloxone
              </MenuItem>
            </Menu>
            <Button
              onClick={(e) => {
                this.topNavRoutes(e, "faq");
              }}
              className={classes.submenu}
            >
              {window.intl.formatMessage({ id: "faq" })}
            </Button>
            <Button
              onClick={(e) => {
                this.topNavRoutes(e, "events");
              }}
              className={classes.submenu}
            >
              {window.intl.formatMessage({ id: "events" })}
            </Button>
            <Button
              onClick={(e) => {
                this.changeLanguage(e)
              }}
              className={classes.submenu}
            >
              {this.state.lang==="en" ? "Spanish" : "English"}
            </Button>
          </Grid>
          <Grid item md={3} className={"adminLogin"}>
            <div className={classes.rightMenu}>
              <NavLink
                to={"/auth/login-page"}
                className={cx(classes.navLink, {
                  [classes.navLinkActive]: this.activeRoute("/auth/login-page"),
                })}
              >
                <ListItemText
                  primary={window.intl.formatMessage({id : "adminLogin"})}
                  disableTypography={true}
                  className={classes.listItemText}
                  style={{ color: "#233750" }}
                />
              </NavLink>
              <PersonIcon
                className={classes.listItemIcon}
                style={{ color: "#233750", fontSize: "20px" }}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </IntlProvider>
    );
  }
}

MainNavBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    categories: state.categories,
    //changeLang : state.langReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPublicCategories: bindActionCreators(getPublicCategories, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(mainNavBarStyle)(MainNavBar));
