import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import api from "../../../../utils/api";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Question from "../../../../components/Drughelp/Question";
import { Button } from "@material-ui/core";

class FeaturesStep extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questionData: {},
      features: []
    };
  }
  componentDidMount() {
    this.getFeatures(this.props.categoryID);
  }

  getFeatures = (category = 4) => {
    api.get(
      `categories/${category}/features`,
      (data = []) => {
        const serverData = this.props.data||{};
        data.forEach(feature => {
          const questions = (feature.questions || []).filter(q => q.active);
          let questionData = this.state.questionData;
          questions.forEach(question => {
            const existing = (serverData[question.id]||{});
            if(question.type==="SWITCH") {
              const answer = (existing.answer === "1");
              questionData[question.id] = {feature_id: feature.id,answer, id: existing.id};
            } else {
              questionData[question.id] = {feature_id: feature.id,answer:existing.answer, id: existing.id};
            }
          });
          this.setState({questionData})
        });
        this.setState({ features: data });
      },
      error => {
        console.log(error);
      }
    );
  };
  isValidated = () => {
    return true;
  };
  sendState = () => {
    const questionData = this.state.questionData;
    return Object.keys(questionData).map(id => {
      let temp = questionData[id]||{};
      temp.question_id = id;
      
      return temp;
    });
  };
  onQuestionChange = (evt, feature, id) => {
    let questionData = this.state.questionData || {};
    let temp = questionData[id]||{};
    temp.feature_id = feature;
    if (evt.target.type === "checkbox") {
      temp.answer = evt.target.checked;
    } else {
      temp.answer = evt.target.value;
    }
    questionData[id] = temp;
    this.setState({ questionData });
  };
  render() {
    const { features = [], questionData = {} } = this.state;
    return (
      <div>
        <GridContainer>
          {features.map((feature = {}) => {
            const questions = (feature.questions || []).filter(q => q.active);
            if (feature.active && questions.length > 0 && (!feature.description.includes("***hide***"))) {
              return (
                <GridItem key={feature.id} xs={12}>
                  <ExpansionPanel>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography color="primary" variant="body2">
                        {feature.name} <small>(click to expand)</small>
                      </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <GridContainer>
                        {feature.description && (
                          <GridItem xs={12}>
                            <Typography variant="body">
                              {feature.description}
                            </Typography>
                        <Button 
                        style={{backgroundColor : "rgba(35, 55, 80, 1)", color : "white", marginTop : "4px"}}
                        size="small"

                        onClick={(evt, id)=> {
                          evt = {target : { checked : true, type : "checkbox"}}
                          questions.filter(
                            question =>
                              question.active && (question.type === "SWITCH"))
                            .forEach(question => {
                              this.onQuestionChange(evt, question.feature, question.id)
                            })
                        }}
                        >
                          Check All
                        </Button>
                        <Button 
                        style={{backgroundColor : "rgba(35, 55, 80, 1)", color : "white", marginTop : "4px", marginLeft : "4px"}}
                        size="small"
                        onClick={(evt, id)=> {
                          evt = {target : { checked : false, type : "checkbox"}}
                          // Simple hardcoded event target, I didn't want to change all the logic code inside the Onchange checkbox
                          // function, so I implemented this in this short and easy way.
                          // This can be done in several easy ways, right now the main point was to ship features faster.
                          questions.filter(
                            question =>
                              question.active && (question.type === "SWITCH"))
                            .forEach(question => {
                              this.onQuestionChange(evt, question.feature, question.id)
                            })
                        }}
                        >
                          Uncheck All
                        </Button>
                          </GridItem>
                        )}
                        {questions.filter(
                              question =>
                                question.active && (question.type === "SWITCH" || question.type === "TEXT")
                            ).map(question => (
                          <GridItem xs={12} key={question.id}>
                            <Question
                              data={question}
                              onChange={(evt, id)=>this.onQuestionChange(evt, feature.id, id)}
                              value={(questionData[question.id]||{}).answer}
                            />
                          </GridItem>
                        ))}
                      </GridContainer>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </GridItem>
              );
            } else {
              return null;
            }
          })}
        </GridContainer>
      </div>
    );
  }
}
export default withStyles({}, { withTheme: true })(FeaturesStep);
