import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  CardActions,
  CardContent,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import ErrorIcon from "@material-ui/icons/Error";
import CallIcon from "@material-ui/icons/Call";
import WebIcon from "@material-ui/icons/Language";
import check_icon from "assets/img/check_icon.png";
import { formatPhoneNumber, getSlotInfo, getUpdatedAddress } from "utils/serviceUtils";
import quickSearchResultsStyle from "assets/jss/material-dashboard-pro-react/views/quickSearchResultsStyle";

import { changeLangSpanish } from "../../../actions/action_lang";
//Internationalization
import { IntlProvider, addLocaleData } from "react-intl";
import { getStore } from "../../../store/configureStore";

import en from "react-intl/locale-data/en";
import es from "react-intl/locale-data/es";
addLocaleData([...en, ...es]);
const intl = window.intl;
//console.log(intl);

const store = getStore();

const ServiceCard = ({
  service,
  classes,
  sortingOption,
  hasUserAllowedLocation,
  zoomOnClick_
}) => {
  const date = new Date();
  const today = date.getDay();
  const currentTime = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
  /*store.dispatch({
    type : "CHANGE_LANG_SPANISH"
  });*/
  const getAddress = (address) => {
    var newAddress = "";
    if (address[1] === ",") {
      newAddress = address.substr(2);
      return newAddress;
    } else {
      return address;
    }
  };

  const getDistanceText = (distance) => {
    if (!distance) return "";

    let distanceText = "";

    if (!hasUserAllowedLocation) {
      distanceText = "Please allow location to view distance.";
    } else if (sortingOption === "6" || sortingOption === "7") {
      distanceText = `${distance.toFixed(2)} Miles away`;
    }

    return distanceText;
  };

  const convertTime = (time) => {
    var splits = time.split(":");
    return parseInt(splits[0]) * 3600 + parseInt(splits[1]) * 60;
  };
  //changeLangSpanish();
  const checkServiceOpen = (timeInfo) => {
    let isServiceOpen = false;
    timeInfo.forEach((day) => {
      if (
        day.pivot.day === today &&
        day.pivot.active === 1 &&
        convertTime(day.pivot.start) < convertTime(currentTime) &&
        convertTime(currentTime) < convertTime(day.pivot.end)
      ) {
        isServiceOpen = true;
      }
    });
    return isServiceOpen;
  };

  const showAccredation = (accreditations) => {
    if (accreditations && accreditations.length > 0 && accreditations[0] == ";") {
      accreditations = accreditations.replace(";", "");
    }
    accreditations = accreditations && accreditations.trim();
    accreditations = accreditations && accreditations.replace(
      new RegExp(String.fromCharCode(160), "g"),
      ""
    ); //&nbsp
    accreditations = accreditations && accreditations.replace(new RegExp(";", "gi"), ", ");

    if (accreditations && accreditations.length > 0) {
      return (
        <Typography style={{ color: "#233750", fontWeight: 700, fontSize: 12 }}>
          <img
            class="card-img-top"
            src={check_icon}
            alt="Accreditations"
            style={{ width: "1rem", height: "1rem" }}
          />{" "}
          {accreditations}
        </Typography>
      );
    } else {
      return "";
    }
  };

  const getSlotData = (slotInf0, updatedAt) => {
    let slotData = getSlotInfo(slotInf0, updatedAt, true);
    let slots, wait_time, last_updated_str;

    if (slotData === "") {
      return "";
    } else if (typeof slotData === 'object' && slotData !== null) {
      slots = slotData['slots'];
      wait_time = slotData['wait_time'];
      last_updated_str = slotData['last_updated_str'];

      if (wait_time === 1)
        return (
          <Grid style={{ display: "flex", flexDirection: "column" }}>
            <Grid style={{ height: "20px" }}>
              <span style={{ display: "flex", flexDirection: "row", margin: "0px !important" }}>
                Current wait time is about <span className={classes.circle} style={{ marginLeft: "5px" }}> 1 </span> day
              </span>
            </Grid>
            <Grid style={{ height: "25px" }}>
              <p>{last_updated_str}</p>
            </Grid>
          </Grid>
        );

      if (wait_time > 1)
        return (
          <Grid style={{ display: "flex", flexDirection: "column" }}>
            <Grid style={{ height: "20px" }}>
              <span style={{ display: "flex", flexDirection: "row", margin: "0px !important" }}> Current wait time is about <span className={classes.circle} style={{ marginLeft: "5px" }}> {wait_time} </span> days</span>
            </Grid>
            <Grid style={{ height: "25px" }}>
              <p>{last_updated_str}</p>
            </Grid>
          </Grid>
        );

      if (slots === 1)
        return (
          <>
            <div className={classes.circle}> {slots} </div> slots is available {last_updated_str}
          </>
        );

      if (slots > 1)
        return (
          <>
            <div className={classes.circle}> {slots} </div> slots are available {last_updated_str}
          </>
        );

      if (slots === 0 && wait_time === 0)
        return (<>
          <div className={classes.circle}> 0 </div> slots are available {last_updated_str}
        </>

        );
    } else {
      return "";
    }
  }

  const setServiceName = (serviceName) => {
    return serviceName.length > 70 ? serviceName.substring(0, 50) + "..." : serviceName;
  };

  const isServiceOpen = checkServiceOpen(service.time_info_data);
  const viewServiceURL = `/auth/service-page/${service.service_id
    }/${service.category_name &&
    service.category_name.replace(/\s+/g, "-").toLowerCase()}/${service.category_id
    }/${service.service_name
      .replace(/\s+/g, "-")
      .toLowerCase()}/${service.service_name
        .replace(/\s+/g, "-")
        .toLowerCase()}/${service.service_type_name
          .replace(/\s+/g, "-")
          .toLowerCase()}`;

  return (
    <Grid
      item
      md={4}
      sm={12}
      className={classes.cardGrid}
      style={{
        margin: "1.5em 0",
        padding: "10px !important",
        paddingTop: 0,
        paddingBottom: 0,
        marginTop: 0,
      }}
      onClick={() => zoomOnClick_(service.agency_name)}
    >
      <Card
        className={classes.cardContainer}
        style={{
          borderRadius: 11,
          marginTop: 0,
          display: "flex"
        }}
      >
        <CardHeader
          className={classes.cardHeader}
          style={{
            backgroundColor: isServiceOpen ? "#233750" : "grey",
            opacity: service.unregistered ? 0.25 : 1,
            padding: "3px 3px 3px 12px",
            color: "white"
          }}
        >
          <Typography style={{ color: "white", fontWeight: "bold" }}>
            {service.service_type_name}
          </Typography>
        </CardHeader>
        <Grid container>
          <Grid item xs={9} sm={9} md={12}>
            <CardContent style={{ marginBottom: "70px" }} className={classes.cardContent}>
              <Typography
                style={{
                  color: "#233750",
                  fontWeight: "normal",
                  fontSize: "13px",
                  fontFamily: "Calibri",
                  display: "flex",
                  whiteSpace: "pre-line",
                  alignItems: "center"
                }}
              >
                {getSlotData(service.slot_infos, service.updated_at)}
              </Typography>
              <Typography
                variant="h5"
                component="h2"
                style={{
                  color: "#d9552c",
                  fontFamily: "Nunito",
                  fontWeight: "bold",
                  fontSize: "17px",
                }}
              >
                {service.agency_name}
              </Typography>
              <Tooltip
                title={service.name}
                placement="bottom"
                classes={{ tooltip: classes.tooltip }}
              >
                <Typography
                  style={{
                    color: "#233750",
                    fontSize: "14px",
                    fontFamily: "Nunito",
                    fontWeight: "bold",
                  }}
                >
                  {setServiceName(service.service_name)}
                </Typography>
              </Tooltip>
              {(() => {
                var address = getAddress(service.address1);
                if (address) {
                  return (
                    <Typography
                      className={classes.pos}
                      style={{
                        color: "#233750",
                        fontWeight: "normal",
                        fontSize: "15px",
                        fontFamily: "Calibri",
                      }}
                    >
                      {address}<br />{`${service.city}, ${getUpdatedAddress(service.state)} ${service.zip}`}
                    </Typography>
                  );
                }
              })()}
              <Typography>
                {showAccredation(
                  service.accreditations
                )}
              </Typography>

              <Typography
                style={{
                  color: "#808080",
                  fontWeight: "normal",
                  fontSize: 16,
                  fontFamily: "Calibri",
                  fontStyle: "Italic",
                }}
              >
                {getDistanceText(service.distance)}
              </Typography>
              {service.unregistered ? (
                <Tooltip
                  title={intl.formatMessage({
                    id: "view.services.unregisteredService",
                  })}
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <IconButton className={classes.iconBtn}>
                    <ErrorIcon fontSize="large" style={{ color: "#d0d1d3" }} />
                  </IconButton>
                </Tooltip>
              ) : (
                ""
              )}
            </CardContent>
          </Grid>
          <Grid item xs={3} sm={3} md={12} className={classes.cardActionsContainer}>
            <CardActions className={classes.attachToBottom + " " + classes.cardActions}>
              <Tooltip
                title={formatPhoneNumber(service.phone_number)}
                placement="bottom"
                classes={{ tooltip: classes.tooltip }}
              >
                <a href={`tel:${service.phone_number}`}>
                  <IconButton className={classes.iconBtn} color="primary">
                    <CallIcon />
                  </IconButton>
                </a>
              </Tooltip>

              <Tooltip
                title={intl.formatMessage({
                  id: "view.services.openWebSite",
                })}
                placement="bottom"
                classes={{ tooltip: classes.tooltip }}
              >
                <a href={service.website} target="_blank" rel="noopener noreferrer">
                  <IconButton
                    className={classes.iconBtn}
                    color="primary"
                    aria-label={intl.formatMessage({
                      id: "view.services.openWebSite",
                    })}
                  >
                    <WebIcon />
                  </IconButton>
                </a>
              </Tooltip>
              <a href={viewServiceURL}>
                <Button size="sm" color="primary" style={{ textTransform: "none" }}>
                  {intl.formatMessage({
                    id: "view.services.viewService",
                  })}
                </Button>
              </a>
            </CardActions>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};

// separate styles
export default withStyles(quickSearchResultsStyle)(ServiceCard);
