const data = {
  title: 'Druphelp.care',
  prev: 'Anterior',
  next: 'Próximo',
  createNew: 'Crear nuevo',
  service: 'Servicio',
  services: 'Servicio',
  agency: 'Agencia',
  agencies: 'Agencias',
  home: 'Inicio',
  about: 'Acerca de',
  menu: 'Menú ',
  faq: 'Preguntas Frecuentes',
  events: 'Eventos',
  adminLogin: 'Inicio de Sesión de Administrador',
  sortBy: 'Ordenar Por',
  recentlyUpdated: 'Recientemente Actualizado',
  agencyNameAtoZ: 'Nombre de la Agencia (A a Z)',
  agencyNameZtoA: 'Nombre de la Agencia (Z a A)',
  distanceFromYou: 'Distancia desde Tu Ubicación',
  distanceFromYourZipCode: 'Distancia desde Tu Código Postal',
  updated: 'actualizado',
  slotsAreAvailable: 'hay cupos disponibles',
  slotIsAvailable: 'hay un cupo disponible',
  user: 'Usuario',
  users: 'Usuarios',
  category: 'Categoría',
  categories: 'Categorías',
  hotline: 'Línea Director',
  hotlines: 'Líneas Directas',
  hotlinecategories: 'Categorías de línea directa',
  feature: 'Función',
  features: 'Funciones',
  timeInfo: 'Información sobre el tiempo',
  timeInfos: 'Informaciones sobre el tiempo',
  search: 'Buscar',
  changePassword: 'Cambiar Contraseña',
  routes: {
    dashboard: 'Panel de control',
    login: 'Iniciar Sesión',
    availabilityStatus: 'Estado de disponibilidad',
  },
  view: {
    services: {
      search: 'Buscar',
      searchServices: 'Buscar Servicios',
      searchResult: '"{count} Resultado encontrado "{term}"',
      openWebSite: 'Abrir sitio web',
      call: 'Llamar',
      searchError: 'Ocurrió un error al buscar servicios',
      noResultsFound: 'No se encontraron resultados coincidentes para la búsqueda dada',
      noServicesAvailable: 'No hay servicios disponibles en este momento',
      viewService: 'Ver Servicio',
      unregisteredService: 'Este servicio de tratamiento no está registrado o no se ha actualizado en más de un año.',
      walkInsWelcome : "Se Aceptan Personas sin Cita"
    },
    categories: {
      search: 'Buscar',
      searchCategories: 'Buscar Categorías',
      searchResult: 'Resultado(s) encontrado(s) para',
      openWebSite: 'Abrir Sito Web',
      searchError: 'Ocurrió un error al buscar servicios',
      noResultsFound: 'No se encontraron resultados coincidentes para la búsqueda dada',
      noCategoriesAvailable: 'No hay categorías disponibles en este momento',
      viewCategory: 'Ver Categoría',
      editCategory: 'Editar categoría',
      addCategory: 'Añadir nueva categoría',
    },
    agencies: {
      search: 'Buscar',
      searchAgencies: 'Buscar Agencias',
      searchResult: 'Resultado(s) encontrado(s) para',
      openWebSite: 'Abrir Sito Web',
      searchError: 'Ocurrió un error al buscar agencias',
      noResultsFound: 'No se encontraron resultados coincidentes para la búsqueda dada',
      noAgenciesAvailable: 'No hay agencias disponibles en este momento',
      viewAgency: 'Ver agencia',
      editAgency: 'Editar agencia',
      addAgency: 'Añadir nueva agencia',
    },
    hotlines: {
      search: 'Buscar',
      searchHotlines: 'Buscar líneas de ayuda',
      searchResult: '{count} Resultados) encontrado(s) para"{term}"',
      searchError: 'Ocurrió un error al buscar líneas de ayuda',
      viewHotline: 'Ver línea de ayuda',
      editHotline: 'Editar línea de ayuda',
      addHotline: 'Añadir nueva línea de ayuda',
      noResultsFound: 'No se encontraron resultados coincidentes para la búsqueda dada',
      noHotlinesAvailable: 'No hay líneas de ayuda disponibles en este momento',
    },
    hotlinecategories: {
      Error: 'Ocurrió un error al cargar la página',
    },
    users: {
      search: 'Buscar',
      searchUsers: 'Buscar usuarios',
      searchResult: '{count} Resultado encontrado"{term}"',
      openWebSite: 'Abrí Sito Web',
      searchError: 'Ocurrió un error al buscar usuarios',
      noResultsFound: 'No se encontraron resultados coincidentes para la búsqueda dada',
      noUsersAvailable: 'No hay usuarios disponibles en este momento',
      viewUser: 'Ver usuario',
      editUser: 'Editar usuario',
      addUser: 'Añadir nuevo usuario',
    },
    categoryEdit: {
      categoryNotFound: 'Categoría no encontrada',
    },
    features: {
      search: 'Buscar',
      searchFeatures: 'Buscar características',
      searchResult: 'Resultado(s) encontrado(s) para',
      openWebSite: 'Abrir sitio web',
      searchError: 'Ocurrió un error al buscar características',
      noResultsFound: 'No se encontraron resultados coincidentes para la búsqueda dada',
      noFeaturesAvailable: 'No hay características disponibles en este momento',
      viewFeature: 'Ver característica',
      editFeature: 'Editar característica',
    },
    searchService: {
      searchServices: 'Buscar por nombre de agencia/nombre de servicio',
      search: 'Buscar',
      downloadResults: 'Descargar Resultados',
    },
    filterOptions: {
      assessment: 'Una entrevista amigable que es el primer paso para obtener ayuda',
      outpatient: 'Consejería individual o grupal',
      intensiveoutpatientprogram: 'Consejería grupal varios días a la semana',
      partialhospitalization: 'Tratamiento diurno para aquellos con necesidades más altas',
      residential: 'Duermes aquí y recibes tratamiento durante el día',
      inpatient:
        'Como un hospital para aquellos que requieren monitoreo médico las 24 horas del día, los 7 días de la semana',
      'recoveryhousingsoberliving': 'Un lugar sobrio y de apoyo para vivir',
      'peersupportrecovery': 'Apoyo entre pares/Recuperación',
      communityadvocates: 'Defensores comunitarios',
      'recoverymeetingsgroups': 'Reuniones/Grupos De recuperación',
      familysupportgroups: 'Grupos de apoyo para familias',
      'hivhepatitistesting': 'Pruebas de VIH/hepatitis',
      obtainingnarcan: 'Obtener Narcan',
      needleexchange: 'Intercambio de agujas',
      drugeducation: 'Educación sobre drogas',
      pregnancyprevention: 'Prevención del embarazo',
      fentanyltestingstrips: 'Pruebas de ventanillo',
      unuseddrugdisposal: 'Eliminación de medicamentos no utilizados',
    },
    defaultText: {
      default: 'Contenido predeterminado',
    },
    mainPage: {
      quickSearchHeading: 'Búsqueda Rápida',
      quickSearchSubHeading: 'Responde algunas preguntas para encontrar servicios de tratamiento',
      advancedSearchHeading: 'Búsqueda Avanzada',
      advancedSearchSubHeading: 'Utiliza más de 100 filtros para encontrar servicios de tratamiento',
    },
    otherResources:{
      otherresources : "Otros recursos",
      harmreductionbutton : 'REDUCCIÓN DE DAÑOS',
      harmreduction : 'Recursos para mantenerse vivo y mejorar la salud personal y comunitaria',
      freenaloxonebutton : 'NALOXONA GRATUITA',
      freenaloxone : 'Obtener naloxona gratuita',
      peerandfamilysupportbutton : "APOYO PARA PARES Y FAMILIAS",
      peerandfamilysupport : "Servicios para aquellos en recuperación y sus familias",
      crisishotlinebutton : "LÍNEAS DE CRISIS",
      crisishotlinedefault : "Access crisis hotlines",
      crisishotlinecount : "Acceder a {count} líneas de crisis"
    },
    categoryNames: {
      harmreduction: 'Reducción de Daños',
      treatmentservices: 'Servicios de Tratamiento',
      'peerandfamilysupport': 'Apoyo de Pares y Familiar',
      'crisishotlines': 'Líneas de Ayuda en Crisis',
    },
    filterYourResults: {
      treatmentservices: {
        treatmentservices: 'Servicios de Tratamiento',
        assessment: 'Evaluación',
        inpatient: 'Hospitalización',
        intensiveoutpatientprogram: 'Programa Intensivo Ambulatorio',
        outpatient: 'Ambulatorio',
        partialhospitalization: 'Hospitalización Parcial',
        recoveryhousingsoberliving: 'Vivienda de Recuperación/Vivienda Seca',
        residential: 'Residencial'
      },
      telehealthservices: {
        telehealthservices: 'Servicios de Telesalud',
        telehealthavailable: 'Telemedicina Disponible'
      },
      eligibilitycriteria: {
        eligibilitycriteria: 'Criterios de Elegibilidad',
        genderanygender: 'Género - Cualquier Género',
        gendermalesonly: 'Género - Solo Hombres',
        genderfemalesonly: 'Género - Solo Mujeres',
        pregnantfemalesaccepted: 'Se Aceptan Mujeres Embarazadas',
        agenominors: 'Edad - Sin Menores',
        ageonlyminors: 'Edad - Solo Menores',
        ageminorsaccepted: 'Edad - Se Aceptan Menores',
        sexoffendersaccepted: 'Se Aceptan Delincuentes Sexuales',
        nosexoffenders: 'No Se Aceptan Delincuentes Sexuales',
        arsonistsaccepted: 'Se Aceptan Incendiarios',
        noarsonists: 'No Se Aceptan Incendiarios',
        criminaljusticeclientsonly: 'Clientes de Justicia Penal',
        criminaljusticeclientsaccepted: 'Solo Se Aceptan Clientes de Justicia Penal',
        childprotectiveservicesclientsaccepted: 'Se Aceptan Clientes de Servicios de Protección Infantil'
      },
      focusesandaccommodations: {
        focusesandaccommodations: 'Enfoques y Adaptaciones',
        transgenderaccommodated: 'Se Acomoda a Personas Transgénero',
        veteransfocused: 'Enfocado en Veteranos',
        lgbtqfocused: 'Enfocado en LGBTQ',
        lgbtqfriendly: 'Amigable con LGBTQ',
        autismspectrumdisorderasdfriendly: 'Amigable con Trastorno del Espectro Autista (TEA)',
        nosmoking: 'No Fumar',
        smokingallowed: 'Se Permite Fumar',
        accompanyingminorsallowed: 'Se Permite la Entrada de Menores Acompañados'
      },
      insuranceaccepted: {
        insuranceaccepted: 'Seguros Aceptados',
        veteransadministrationvabenefits: 'Beneficios de la Administración de Veteranos (VA)',
        medicaid: 'Medicaid',
        medicare: 'Medicare',
        privateinsurance: 'Seguro Privado',
        selfpay: 'Pago por Cuenta Propia',
        criminaljusticecourtreferral: 'Derivación de Justicia Penal/Tribunal',
        uninsured: 'Sin Seguro'
      },
      mentalhealthconcerns: {
        mentalhealthconcerns: 'Problemas de Salud Mental',
        anxiety: 'Ansiedad',
        depression: 'Depresión',
        posttraumaticstressdisorderptsd: 'Trastorno de Estrés Postraumático (TEPT)',
        attentiondeficithyperactivitydisorderadhd: 'Trastorno por Déficit de Atención e Hiperactividad (TDAH)',
        bipolardisorder: 'Trastorno Bipolar',
        personalitydisordersborderlineantisocialetc: 'Trastornos de la Personalidad (Límite, Antisocial, etc.)',
        obsessivecompulsivedisorderocd: 'Trastorno Obsesivo-Compulsivo (TOC)',
        schizophreniapsychoticdisorders: 'Esquizofrenia y Trastornos Psicóticos',
        eatingdisorders: 'Trastornos de la Alimentación'
      },
      interventionsused: {
        interventionsused: 'Intervenciones Utilizadas',
        cognitivebehavioraltherapy: 'Terapia Cognitivo-Conductual',
        motivationalinterviewing: 'Entrevista Motivacional',
        '12stepprogram': 'Programa de 12 Pasos',
        harmreduction: 'Reducción de Daños',
        traumafocusedcounseling: 'Consejería Enfocada en el Trauma',
        dialecticalbehaviortherapy: 'Terapia Dialéctica Conductual',
        interactivejournaling: 'Diario Interactivo',
        solutionfocused: 'Enfoque en Soluciones',
        psychoeducation: 'Psicoeducación',
        contingencymanagementtherapy: 'Terapia de Manejo de Contingencias'
      },
      matsuboxone: {
        matsuboxone: 'TAM - Suboxone',
        provideorprescribe: 'Proveer o Recetar',
        allowbutdonotprovide: 'Permitir pero no Proveer',
        donotallow: 'No Permitir'
      },
      matsublocadeinjection: {
        matsublocadeinjection: 'TAM - Inyección Sublocade',
        provideorprescribe: 'Proveer o Recetar',
        allowbutdonotprovide: 'Permitir pero no Proveer',
        donotallow: 'No Permitir'
      },
      matmethadone: {
        matmethadone: 'TAM - Metadona',
        provideorprescribe: 'Proveer o Recetar',
        allowbutdonotprovide: 'Permitir pero no Proveer',
        donotallow: 'No Permitir'
      },
      matvivitrol: {
        matvivitrol: 'TAM - Vivitrol',
        provideorprescribe: 'Proveer o Recetar',
        allowbutdonotprovide: 'Permitir pero no Proveer',
        donotallow: 'No Permitir'
      },
      matcampral: {
        matcampral: 'TAM - Campral',
        provideorprescribe: 'Proveer o Recetar',
        allowbutdonotprovide: 'Permitir pero no Proveer',
        donotallow: 'No Permitir'
      },
      matantabuse: {
        matantabuse: 'TAM - Antabuse',
        provideorprescribe: 'Proveer o Recetar',
        allowbutdonotprovide: 'Permitir pero no Proveer',
        donotallow: 'No Permitir'
      },
      additionalservicesonsite: {
        additionalservicesonsite: 'Servicios Adicionales en el Sitio',
        applyingformedicaid: 'Solicitud para Medicaid',
        birthcontrol: 'Anticonceptivos',
        casemanagement: 'Gestión de Casos',
        childcare: 'Cuidado Infantil',
        compassionateprenatalcare: 'Atención Prenatal Compasiva',
        eapcounseling: 'Consejería EAP',
        employmenttraining: 'Capacitación Laboral',
        familysupportclasses: 'Clases de Apoyo Familiar',
        hivhepatitistesting: 'Pruebas de VIH/Hepatitis',
        housingassistance: 'Asistencia para la Vivienda',
        legalassistance: 'Asistencia Legal',
        needleexchange: 'Intercambio de Agujas',
        obtainingid: 'Obtención de Identificación',
        obtainingnarcan: 'Obtención de Narcan',
        peerrecovery: 'Recuperación entre Pares',
        recoverymeetings: 'Reuniones de Recuperación',
        primarycaredoctor: 'Médico de Atención Primaria',
        smokingcessation: 'Abandono del Tabaco',
        transportationassistance: 'Asistencia de Transporte',
        serviceprovidedathome: 'Servicio Proporcionado en Casa',
        serviceprovidedathospital: 'Servicio Proporcionado en el Hospital',
        serviceprovidedatjail: 'Servicio Proporcionado en la Cárcel',
        asllanguageassistance: 'Asistencia en Lenguaje de Señas Americano',
        spanishlanguageassistance: 'Asistencia en Español',
        chineselanguageassistance: 'Asistencia en Chino',
        computertranslationinterpretationservice: 'Servicio de Traducción/Interpretación por Computadora'
      },
      walkinswelcome: {
        walkinswelcome : "Se Aceptan Personas sin Cita",
        yes: 'sí',
        no: 'no'
      }
    },
    servicePage: {
      slotavailability: 'Disponibilidad de espacios',
      open: {
        servicehours: 'Horario de servicio',
        walkinhours: 'Horario de atención sin cita',
        intakehours: 'Horario de admisión',
      },
      agencydescription: 'Descripción de la agencia',
      servicedescription: 'Descripción del servicio',
      additionalinformationandcriteria: 'Información adicional y criterios',
      provideorprescribe: 'Proveer o Recetar',
      allowbutdonotprovide: 'Permitir pero no Proveer',
      donotallow: 'No Permitir'
    },
    privacypolicy: {
      privacypolicy: 'Política de privacidad',
      thisprivacypolicyiseffectiveforallusers: {
        title: 'Esta Política de Privacidad es vigente para todos los usuarios.',
        description:
          'Gracias por visitar <i>drughelp.care</i>, un sitio web diseñado para mejorar el acceso al tratamiento para el uso de sustancias. En concordancia con las disposiciones de la Ley de Seguridad y Privacidad en Internet, la Ley de Libertad de Información y la Ley de Protección de Privacidad Personal, esta política describe las prácticas de privacidad de drughelp.care con respecto a la información recopilada de los usuarios de este sitio web. Esta política detalla qué información se recopila y cómo se utiliza dicha información.<br><br> Los términos “nosotros”, “nos” y “<i>drughelp.care</i>” se refieren a <i>drughelp.care</i>, un sitio web desarrollado por investigadores de la Universidad Estatal de Cleveland en Cleveland, Ohio. Al usar este sitio, usted consiente la recopilación, el uso y la divulgación de su información según lo descrito en esta Política de Privacidad.',
      },
      informationcollectedautomaticallywhenyouvisitthiswebsiteandhowweuseit: {
        title: 'Información recopilada automáticamente cuando visita este sitio web y cómo la utilizamos',
        description:
          'Podemos recopilar y almacenar información sobre usted en relación con su uso del sitio. Utilizaremos la dirección IP de su computadora para diferenciarlo de otros usuarios. Recopilaremos información sobre los filtros de búsqueda, las opciones de ordenamiento y los términos de búsqueda que utilice. Usaremos esta información para personalizar lo que ve en el sitio web en función de su historial de uso, permitiéndole acceder más rápidamente a la información que necesita.',
      },
      accesstoyourlocation: {
        title: 'Acceso a su ubicación',
        description:
          'Tiene la opción de activar los servicios de ubicación para permitirnos brindarle una mejor asistencia. No recopilamos información sobre su ubicación automáticamente. Debe autorizarnos a acceder a su ubicación para utilizar ciertas funciones de este sitio web, como encontrar servicios cercanos. Sin embargo, el resto de la información en este sitio web funciona completamente sin permitirnos acceder a su ubicación.',
      },
      informationcollectedwhenyoucreateanagencyaccount: {
        title: 'Información recopilada cuando crea una cuenta de agencia',
        description:
          "Si crea una cuenta de agencia en <i>drughelp.care</i>, podemos almacenar y utilizar la información que proporcione durante ese proceso, como su nombre completo, dirección de correo electrónico, nombre de la agencia, dirección de la agencia y número de teléfono. Podemos mostrar públicamente el nombre de su agencia, así como cualquier otro contenido que envíe durante el proceso de registro, como parte del perfil de su cuenta de agencia. Su dirección de correo electrónico y número de teléfono pueden ser utilizados para contactarlo. Si cree que alguien ha creado una cuenta no autorizada en representación de su agencia, puede solicitar su eliminación contactándonos en <a href='mailto:admin@drughelp.care' style='color:#3d97d3'><strong>admin@drughelp.care</strong></a>.",
      },
      cookies: {
        title: 'Cookies',
        description:
          'Las cookies se utilizan para distinguir entre los usuarios de este sitio web. Esta es una práctica estándar entre los sitios web de Internet. Las cookies pueden tener identificadores únicos y residir en su computadora, dispositivo móvil, en correos electrónicos que le enviamos, en nuestras páginas web u otros lugares. Este sitio web utiliza cookies para almacenar información sobre su uso del sitio, incluido su historial de filtros de búsqueda, opciones de ordenamiento o términos que utiliza para buscar en nuestro sitio web.',
      },
      informationcollectedwhenyouemailus: {
        title: 'Información recopilada cuando nos envía un correo electrónico',
        description:
          'Durante su visita a este sitio web, puede enviar un correo electrónico a <i>drughelp.care</i>. Se recopilarán su dirección de correo electrónico y el contenido de su mensaje. La información recopilada incluye texto, audio, video y formatos de información gráfica incluidos en el mensaje. Su dirección de correo electrónico y la información contenida en su mensaje se utilizarán para responderle, abordar los problemas que identifique y mejorar este sitio web.',
      },
      security: {
        title: 'Seguridad',
        description:
          'Utilizamos diversas medidas de protección para resguardar la información personal que nos envía, tanto durante la transmisión como al recibirla. Sin embargo, ningún método de transmisión a través de Internet o dispositivos móviles es 100% seguro. Por lo tanto, nos esforzaremos por proteger su información personal, pero no podemos garantizar su seguridad absoluta.',
      },
      contact: {
        title: 'Contacto',
        description:
          "Por favor, <a href='mailto:admin@drughelp.care' style='color:#3d97d3'><strong>contáctenos</strong></a> si tiene alguna pregunta o inquietud sobre nuestra Política de Privacidad.",
      },
    },
}

};
export default data;
