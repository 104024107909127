import {CHANGE_LANG_ENGLISH, CHANGE_LANG_SPANISH} from "../utils/types";
const initialState = {
    lang : "en"
};

export default function langReducer(state = initialState, action) {
    const {type, payload} = action;
    switch (type) {
        case `${CHANGE_LANG_SPANISH}`:
            localStorage.setItem('lang', "es");
            return {...state, lang:"es"};
        
        case `${CHANGE_LANG_ENGLISH}`:
            localStorage.setItem('lang', "en");
            return {...state, lang:"en"};
        default:
            return state;
    }
}