const data = {
  title: "Drughelp.care",
  prev: "PREV",
  next: "NEXT",
  createNew: "Create new",
  service: "Service",
  services: "Services",
  agency: "Agency",
  agencies: "Agencies",
  home: "Home",
  about : "About",
  menu : "Menu",
  faq : "FAQs",
  events: "Events",
  adminLogin : "Admin Login",
  sortBy : "Sort By",
  recentlyUpdated : 'Recently Updated',
  agencyNameAtoZ : 'Agency Name (A to Z)',
  agencyNameZtoA : 'Agency Name (Z to A)',
  distanceFromYou: 'Distance From You',
  distanceFromYourZipCode : 'Distance From Your Zip Code',
  updated: 'updated',
  slotsAreAvailable : 'slots are available',
  slotIsAvailable: 'slot is available',
  user: "User",
  users: "Users",
  category: "Category",
  categories: "Categories",
  hotline: "Hotline",
  hotlines: "Hotlines",
  hotlinecategories: "Hotline Categories",
  feature: "Feature",
  features: "Features",
  timeInfo: "Time Information",
  timeInfos: "Time Informations",
  search: "Search",
  changePassword: "Change Password",
  routes: {
    dashboard: "Dashboard",
    login: "Login",
    availabilityStatus: "Availability Status",
  },
  view: {
    services: {
      search: "Search",
      searchServices: "Search Services",
      searchResult: '{count} result(s) found for "{term}"',
      openWebSite: "Open Website",
      call: "Call",
      searchError: "Error occured while searching for services",
      noResultsFound: "No matching results found for given search",
      noServicesAvailable: "No services available at this moment",
      viewService: "View service",
      unregisteredService:
        "This treatment service is unregistered or has not been updated in over a year.",
      walkInsWelcome : "Walk-Ins Welcome"
    },
    categories: {
      search: "Search",
      searchCategories: "Search Categories",
      searchResult: '{count} result(s) found for "{term}"',
      openWebSite: "Open Website",
      searchError: "Error occured while searching for categories",
      noResultsFound: "No matching results found for given search",
      noCategoriesAvailable: "No categories available at this moment",
      viewCategory: "View category",
      editCategory: "Edit category",
      addCategory: "Add new category",
    },
    agencies: {
      search: "Search",
      searchAgencies: "Search Agencies",
      searchResult: '{count} result(s) found for "{term}"',
      openWebSite: "Open Website",
      searchError: "Error occured while searching for agencies",
      noResultsFound: "No matching results found for given search",
      noAgenciesAvailable: "No agencies available at this moment",
      viewAgency: "View agency",
      editAgency: "Edit agency",
      addAgency: "Add new agency",
    },
    hotlines: {
      search: "Search",
      searchHotlines: "Search Hotlines",
      searchResult: '{count} result(s) found for "{term}"',
      searchError: "Error occured while searching for hotlines",
      viewHotline: "View Hotline",
      editHotline: "Edit Hotline",
      addHotline: "Add New Hotline",
      noResultsFound: "No matching results found for given search",
      noHotlinesAvailable: "No hotlines available at this moment",
    },
    hotlinecategories: {
      Error: "Error occured while loading the page",
    },
    users: {
      search: "Search",
      searchUsers: "Search Users",
      searchResult: '{count} result(s) found for "{term}"',
      openWebSite: "Open Website",
      searchError: "Error occured while searching for users",
      noResultsFound: "No matching results found for given search",
      noUsersAvailable: "No users available at this moment",
      viewUser: "View user",
      editUser: "Edit user",
      addUser: "Add new user",
    },
    categoryEdit: {
      categoryNotFound: "Category not found.",
    },
    features: {
      search: "Search",
      searchFeatures: "Search Features",
      searchResult: '{count} result(s) found for "{term}"',
      openWebSite: "Open Website",
      searchError: "Error occured while searching for Features",
      noResultsFound: "No matching results found for given search",
      noFeaturesAvailable: "No Features available at this moment",
      viewFeature: "View feature",
      editFeature: "Edit feature",
    },
    searchService: {
      searchServices: "Search by Agency Name/Service Name",
      search: "Search",
      downloadResults: "Download Results"
    },
    filterOptions: {
      assessment: "A friendly interview that is the first step in getting help",
      outpatient: "Individual or group counseling",
      intensiveoutpatientprogram: "Group counseling several days each week",
      partialhospitalization: "Day treatment for those with higher needs",
      residential: "You sleep here and get treatment during the day",
      inpatient:
        "Like a hospital for those who require 24/7 medical monitoring",
      "recoveryhousingsoberliving": "A sober, supportive place to live",
      "peersupportrecovery": "Peer Support/Recovery",
      communityadvocates: "Community Advocates",
      "recoverymeetingsgroups": "Recovery Meetings/Groups",
      familysupportgroups: "Family Support Groups",
      "hivhepatitistesting": "HIV/Hepatitis Testing",
      obtainingnarcan: "Obtaining Narcan",
      needleexchange: "Needle Exchange",
      drugeducation: "Drug Education",
      pregnancyprevention: "Pregnancy Prevention",
      fentanyltestingstrips: "Fentanyl Testing Strips",
      unuseddrugdisposal: "Unused Drug Disposal",
    },
    defaultText: {
      default: "Default Content",
    },
    mainPage:{
      quickSearchHeading : "Quick Search",
      quickSearchSubHeading : "Answer a few questions to find treatment services",
      advancedSearchHeading : "Advanced Search",
      advancedSearchSubHeading : "Use more than 100 filters to find treatment services"
    },
    otherResources:{
      otherresources : "Other Resources",
      harmreductionbutton : "HARM REDUCTION",
      harmreduction : "Resources to stay alive and improve personal and community health",
      freenaloxonebutton : "FREE NALOXONE",
      freenaloxone : "Obtain free naloxone",
      peerandfamilysupportbutton : "PEER AND FAMILY SUPPORT",
      peerandfamilysupport : "Services for those in recovery and their families",
      crisishotlinebutton : "CRISIS HOTLINES",
      crisishotlinedefault : "Access crisis hotlines",
      crisishotlinecount : "Access {count} crisis hotlines"
    },
    categoryNames : {
      harmreduction : "Harm Reduction",
      treatmentservices : "Treatment Services",
      "peerandfamilysupport": "Peer and Family Support",
      "crisishotlines" : "Crisis Hotlines"
    },
    filterYourResults: {
      treatmentservices:{
        treatmentservices : 'Treatment Services',
        assessment : 'Assessment',
        inpatient : 'Inpatient',
        intensiveoutpatientprogram : 'Intensive Outpatient Program', 
        outpatient : 'Outpatient',
        partialhospitalization : 'Partial Hospitalization',
        recoveryhousingsoberliving : 'Recovery Housing/Sober Living',
        residential : 'Residential'
      },
      telehealthservices : {
        telehealthservices : 'Telehealth Services',
        telehealthavailable : 'Telehealth Available'
      },
      eligibilitycriteria:{
          eligibilitycriteria : 'Eligibility Criteria',
          genderanygender: "Gender - Any Gender",
          gendermalesonly: "Gender - Males Only",
          genderfemalesonly: "Gender - Females Only",
          pregnantfemalesaccepted: "Pregnant Females Accepted",
          agenominors: "Age - No Minors",
          ageonlyminors: "Age - Only Minors",
          ageminorsaccepted: "Age - Minors Accepted",
          sexoffendersaccepted: "Sex Offenders Accepted",
          nosexoffenders: "No Sex Offenders",
          arsonistsaccepted: "Arsonists Accepted",
          noarsonists: "No Arsonists",
          criminaljusticeclientsonly: "Criminal Justice Clients Only",
          criminaljusticeclientsaccepted: "Criminal Justice Clients Accepted",
          childprotectiveservicesclientsaccepted: "Child Protective Services Clients Accepted"
      },
      focusesandaccommodations : {
        focusesandaccommodations : "Focuses and Accommodations",
        transgenderaccommodated: "Transgender accommodated",
        veteransfocused: "Veterans focused",
        lgbtqfocused: "LGBTQ focused",
        lgbtqfriendly: "LGBTQ friendly",
        autismspectrumdisorderasdfriendly: "Autism Spectrum Disorder (ASD) Friendly",
        nosmoking: "No Smoking",
        smokingallowed: "Smoking allowed",
        accompanyingminorsallowed: "Accompanying minors allowed"
      },
      insuranceaccepted : {
        insuranceaccepted : "Insurance Accepted",
        veteransadministrationvabenefits: "Veterans Administration (VA) benefits",
        medicaid: "Medicaid",
        medicare: "Medicare",
        privateinsurance: "Private insurance",
        selfpay: "Self-Pay",
        criminaljusticecourtreferral: "Criminal Justice/Court referral",
        uninsured: "Uninsured"
      },
      mentalhealthconcerns : {
        mentalhealthconcerns : "Mental Health Concerns",
        anxiety: "Anxiety",
        depression: "Depression",
        posttraumaticstressdisorderptsd: "Post-Traumatic Stress Disorder (PTSD)",
        attentiondeficithyperactivitydisorderadhd: "Attention-Deficit/Hyperactivity Disorder (ADHD)",
        bipolardisorder: "Bipolar Disorder",
        personalitydisordersborderlineantisocialetc: "Personality Disorders (Borderline, Antisocial, etc.)",
        obsessivecompulsivedisorderocd: "Obsessive Compulsive Disorder (OCD)",
        schizophreniapsychoticdisorders: "Schizophrenia & Psychotic Disorders",
        eatingdisorders: "Eating Disorders"
      },
      interventionsused : {
        interventionsused : "Interventions Used",
        cognitivebehavioraltherapy: "Cognitive Behavioral Therapy",
        motivationalinterviewing: "Motivational Interviewing",
        "12stepprogram": "12-Step Program",
        harmreduction: "Harm reduction",
        traumafocusedcounseling: "Trauma-Focused Counseling",
        dialecticalbehaviortherapy: "Dialectical Behavior Therapy",
        interactivejournaling: "Interactive Journaling",
        solutionfocused: "Solution-Focused",
        psychoeducation: "Psychoeducation",
        contingencymanagementtherapy: "Contingency Management Therapy"
      },
      matsuboxone : {
        matsuboxone : "MAT - Suboxone",
        "provideorprescribe": "Provide or prescribe",
        "allowbutdonotprovide": "Allow but do not provide",
        "donotallow": "Do not allow"
      },
      matsublocadeinjection : {
        matsublocadeinjection : "MAT - Sublocade Injection",
        "provideorprescribe": "Provide or prescribe",
        "allowbutdonotprovide": "Allow but do not provide",
        "donotallow": "Do not allow"
      },
      matmethadone : {
        matmethadone : "MAT - Methadone",
        "provideorprescribe": "Provide or prescribe",
        "allowbutdonotprovide": "Allow but do not provide",
        "donotallow": "Do not allow"
      },
      matvivitrol : {
        matvivitrol : "MAT - Vivitrol",
        "provideorprescribe": "Provide or prescribe",
        "allowbutdonotprovide": "Allow but do not provide",
        "donotallow": "Do not allow"
      },
      matcampral : {
        matcampral : "MAT - Campral",
        "provideorprescribe": "Provide or prescribe",
        "allowbutdonotprovide": "Allow but do not provide",
        "donotallow": "Do not allow"
      },
      matantabuse: {
        matantabuse : "MAT - Antabuse",
        "provideorprescribe": "Provide or prescribe",
        "allowbutdonotprovide": "Allow but do not provide",
        "donotallow": "Do not allow"
      },
      additionalservicesonsite : {
        additionalservicesonsite : "Additional Services On-Site",
        "applyingformedicaid": "Applying for Medicaid",
        "birthcontrol": "Birth Control",
        "casemanagement": "Case Management",
        "childcare": "Childcare",
        "compassionateprenatalcare": "Compassionate prenatal care",
        "eapcounseling": "EAP Counseling",
        "employmenttraining": "Employment training",
        "familysupportclasses": "Family support classes",
        "hivhepatitistesting": "HIV/Hepatitis testing",
        "housingassistance": "Housing assistance",
        "legalassistance": "Legal assistance",
        "needleexchange": "Needle exchange",
        "obtainingid": "Obtaining ID",
        "obtainingnarcan": "Obtaining Narcan",
        "peerrecovery": "Peer recovery",
        "recoverymeetings": "Recovery meetings",
        "primarycaredoctor": "Primary care doctor",
        "smokingcessation": "Smoking cessation",
        "transportationassistance": "Transportation assistance",
        "serviceprovidedathome": "Service provided at home",
        "serviceprovidedathospital": "Service provided at hospital",
        "serviceprovidedatjail": "Service provided at jail",
        "asllanguageassistance": "ASL language assistance",
        "spanishlanguageassistance": "Spanish language assistance",
        "chineselanguageassistance": "Chinese language assistance",
        "computertranslationinterpretationservice": "Computer Translation/Interpretation Service"
      },
      walkinswelcome : {
        walkinswelcome : "Walk-Ins Welcome",
        yes : "yes",
        no : "no"
      }

  },
  servicePage : {
    slotavailability : "Slot Availability",
    open : {
      servicehours : "Service Hours",
      walkinhours : "Walk-In Hours",
      intakehours : "Intake Hours"
    },
    agencydescription : "Agency Description",
    servicedescription : "Service Description",
    additionalinformationandcriteria : "Additional Information and Criteria",
    "provideorprescribe": "Provide or prescribe",
    "allowbutdonotprovide": "Allow but do not provide",
    "donotallow": "Do not allow"
  },
  privacypolicy : {
    privacypolicy : "Privacy Policy",
    thisprivacypolicyiseffectiveforallusers: {
      title : "This Privacy Policy is effective for all users.",
      description : "Thank you for visiting <i>drughelp.care</i>, a website designed to enhance access to substance use treatment. Consistent with the provisions of the Internet Security and Privacy Act, the Freedom of Information Law, and the Personal Privacy Protection Law, this policy describes the <i>drughelp.care</i> privacy practices regarding information collected from users of this website. This policy describes what information is collected and how that information is used.<br><br> The terms “we”, “us”, and “<i>drughelp.care</i>” refer to drughelp.care, a website being developed by researchers at Cleveland State University in Cleveland, Ohio. When you use the site, you consent to our collection, use, and disclosure of information about you as described in this Privacy Policy."
    },
    informationcollectedautomaticallywhenyouvisitthiswebsiteandhowweuseit : {
      title : "Information Collected Automatically When You Visit This Website and How We Use It",
      description : "We may collect and store information about you in connection with your use of the site. We will use your computer’s IP address to distinguish among you and other users. We will collect information about the search filters, sorting options, and search terms that you use. We will use this information to personalize what you see on the website based on your history of use, allowing you to access the information that you need more quickly."
    },
    accesstoyourlocation : {
      title : "Access To Your Location",
      description : "You have the option to opt into location services to allow us to better assist you. We do not automatically collect information about your location. You must allow us to access your location to utilize certain features of this website, including finding services near you. However, the rest of the information on this website is fully functional without allowing us to access your location."
    },
    informationcollectedwhenyoucreateanagencyaccount : {
      title : "Information Collected When You Create an Agency Account",
      description : "If you create a <i>drughelp.care</i> Agency Account, we may store and use the information you provide during that process, such as your full name, email address, agency name, agency address, and phone number. We may publicly display your agency name, as well as any other content you submit through the registration process, as part of your agency account profile. Your email address and phone number can be used to contact you. If you believe that someone has created an unauthorized account depicting your agency, you can request its removal by contacting us at <a href='mailto:admin@drughelp.care' style='color:#3d97d3'><strong>admin@drughelp.care</strong></a>."
    },
    cookies : {
      title : "Cookies",
      description : "Cookies are used to distinguish among users of this website. This is a standard practice among Internet websites. Cookies may have unique identifiers, and reside on your computer, mobile device, in emails we send to you, on our web pages, or other places. This website uses cookies to store information about your use of the website, including your history of search filters, sorting options, or terms that you use to search our website."
    },
    informationcollectedwhenyouemailus : {
      title : "Information Collected When You Email Us",
      description : "During your visit to this website you may send an e-mail to <i>drughelp.care</i>. Your e-mail address and the contents of your message will be collected. The information collected includes text, audio, video, and graphic information formats included in the message. Your e-mail address and the information included in your message will be used to respond to you, to address issues you identify, and to improve this website."
    },
    security : {
      title : "Security",
      description : "We use various defenses to protect the personal information submitted to us, both during transmission and when we receive it. However, no method of transmission over the Internet or via mobile device is 100% secure. Therefore, we will strive to protect your personal information, but we cannot guarantee its absolute security."
    },
    contact : {
      title : "Contact",
      description : "Please <a href='mailto:admin@drughelp.care' style='color:#3d97d3'><strong>contact</strong></a> us if you have any questions/concerns on our Privacy Policy."
    }
  },
  quickSearchQuestions : {
    note : "Drughelp.care does not store your search activity. Your answers to these questions will only be used to match you to the best treatment services.",
    
  }

  },
};
export default data;
