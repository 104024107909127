const sortByOptionsForTreatmentServices = [
  {
    name : window.intl.formatMessage({id : "recentlyUpdated"}),
    //name: "Recently Updated",
    value: "1",
  },
  {
    // name: "Agency Name(A to Z)",
    name : window.intl.formatMessage({id : "agencyNameAtoZ"}),
    value: "2",
  },
  {
    //name: "Agency Name(Z to A)",
    name : window.intl.formatMessage({id : "agencyNameZtoA"}),
    value: "3",
  },
  {
    //name: "Distance From You",
    name : window.intl.formatMessage({id : "distanceFromYou"}),
    value: "6",
  },
  {
    //name: "Distance From Your Zip Code",
    name : window.intl.formatMessage({id : "distanceFromYourZipCode"}),
    value: "7",
  },
  {
    name: "Walk-Ins Only",
    value: "8",
  },
]

const sortByOptionsForOtherSerivces = [
  {
    name: "Distance From You",
    value: "6",
  },
  {
    name: "Distance From Your Zip Code",
    value: "7",
  },
]

const TREATMENT_SERVICE_ID = "3";

const getSortByOptions = (serviceId) => {
  if(serviceId === TREATMENT_SERVICE_ID) {
    return sortByOptionsForTreatmentServices;
  }
  return sortByOptionsForOtherSerivces;
}

export default getSortByOptions;